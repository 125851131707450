import styled from 'styled-components'
import device from '../../utils/device-size'

export default styled.div`
  width: 100%;
  .vehicle-wrapper {
    width: 100%;
    height: 100%;
    .total-wrapper {
      width: 50%;
      justify-content: center;
      align-items: center;
      .total-circle {
        width: 252px;
        height: 252px;
        border-radius: 50%;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        border: 2px solid #981214;
        background-color: #2e2e2e;
        &.loading-status {
          border: none;
        }
        .loading {
          border-radius: 50%;
        }
        .value {
          font-family: 'Prompt';
          font-size: 48px;
          color: #f2f2f2;
        }
        .total-label {
          font-family: 'Prompt';
          font-size: 12px;
          text-transform: uppercase;
        }
      }
    }
    .each-vehicle-wrapper {
      width: 50%;
      display: flex;
      align-items: center;
      .vehicle-count-container {
        width: 100%;
        padding-right: 20px;
        .loading {
          width: 100%;
          height: 28px;
          margin: 0 10px;
        }
        .vehicle-count-wrapper {
          width: 100%;
          margin-bottom: 6px;
          font-size: 16px;
          .vehicle-count-label {
            width: 164px;
          }
          .vehicle-count-value {
            width: 110px;
            text-align: end;
          }
        }
      }
    }
  }

  @media only screen and (min-width: ${device.minSizeL}) and (max-width: ${device.maxSizeL}) {
    .vehicle-wrapper {
      .total-wrapper {
        .total-circle {
          width: 184px;
          height: 184px;
          .value {
            font-size: 40px;
          }
        }
      }
      .each-vehicle-wrapper {
        .vehicle-count-container {
          width: 100%;
          padding-right: 20px;
          .loading {
            width: 150px;
            height: 20px;
          }
          .vehicle-count-wrapper {
            .vehicle-count-label {
              width: 132px;
            }
            .vehicle-count-value {
              width: 72px;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: ${device.minSizeM}) and (max-width: ${device.maxSizeM}) {
    .vehicle-wrapper {
      .total-wrapper {
        width: 50%;
        justify-content: center;
        align-items: center;
        .total-circle {
          width: 219px;
          height: 219px;
        }
      }
      .each-vehicle-wrapper {
        .vehicle-count-container {
          .vehicle-count-wrapper {
            .vehicle-count-value {
              padding-right: 48px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: ${device.maxSizeS}) {
    .vehicle-wrapper {
      .total-wrapper {
        width: 50%;
        .total-circle {
          width: 209px;
          min-width: 209px;
          height: 209px;
        }
      }
      .each-vehicle-wrapper {
        .vehicle-count-container {
          padding-right: 0px;
          .vehicle-count-wrapper {
            .vehicle-count-label {
              width: 132px;
            }
            .vehicle-count-value {
              width: 81px;
              margin: 2px 34px 2px 24px;
            }
          }
        }
      }
    }
  }
`
