import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import pluralize from 'pluralize'
import CardWrapper from '../CardWrapper/dynamic'
import SkeletonAnimation from '../SkeletonAnimation/dynamic'
import VehicleCountStyled from './styled'

import { withTranslation } from '../../../i18n'

export class VehicleCount extends React.PureComponent {
  getTotalVisitorCircle() {
    const total = this.props.vehicleCountByType.reduce((prev, current) => {
      return prev + current.count
    }, 0)
    const totalVisitorText = pluralize(this.props.t('total_vehicles'), total)
    let className = 'total-circle'
    let output = (
      <>
        <NumberFormat
          value={total}
          displayType="text"
          thousandSeparator={true}
          renderText={value => (
            <div className="value" data-test="lbl-vehicle-count-total-value">
              {value}
            </div>
          )}
        />
        <div className="total-label" data-test="lbl-vehicle-count-total">
          {totalVisitorText}
        </div>
      </>
    )
    if (this.props.loading) {
      className += ' loading-status'
      output = <SkeletonAnimation id="visitor-circle-skeleton-loading" className="loading" />
    }
    return (
      <div className="total-wrapper flex">
        <div className={`${className} flex flex-column align-items-center justify-center`}>{output}</div>
      </div>
    )
  }

  getEachVehicleCount() {
    const eachVehicleCount = this.props.vehicleCountByType.map(vehicleCountData => {
      return (
        <div key={vehicleCountData.vehicleType} className="vehicle-count-wrapper flex align-items-center">
          <div className="vehicle-count-label" data-test="lbl-vehicle-count-trucks">
            {vehicleCountData.count === 0 && vehicleCountData.vehicleType !== 'others'
              ? pluralize.singular(this.props.t(vehicleCountData.vehicleType), vehicleCountData.vehicleType)
              : pluralize(this.props.t(vehicleCountData.vehicleType))}
          </div>
          <NumberFormat
            value={vehicleCountData.count}
            displayType="text"
            thousandSeparator={true}
            renderText={value => (
              <div className="vehicle-count-value" data-test="lbl-vehicle-count-trucks-value">
                {value}
              </div>
            )}
          />
        </div>
      )
    })
    const loadingSkeleton = [...new Array(6)].map((_, index) => {
      return (
        <div key={index} className="vehicle-count-wrapper flex align-items-center">
          <SkeletonAnimation id="vehicle-count-skeleton-loading" className="loading" />
          <SkeletonAnimation id="vehicle-count-skeleton-loading" className="loading" />
        </div>
      )
    })
    return (
      <div className="each-vehicle-wrapper">
        <div className="vehicle-count-container">{this.props.loading ? loadingSkeleton : eachVehicleCount}</div>
      </div>
    )
  }

  renderCardContent() {
    return (
      <div className="vehicle-wrapper flex">
        {this.getTotalVisitorCircle()}
        {this.getEachVehicleCount()}
      </div>
    )
  }

  renderContent() {
    return (
      <CardWrapper title={this.props.t('vehicle_count')} id={'header-vehicle-count'}>
        {this.renderCardContent()}
      </CardWrapper>
    )
  }

  render() {
    return <VehicleCountStyled className={this.props.className}>{this.renderContent()}</VehicleCountStyled>
  }
}

VehicleCount.defaultProps = {
  loading: false,
  vehicleCountByType: [],
}

VehicleCount.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  vehicleCountByType: PropTypes.arrayOf(
    PropTypes.shape({
      vehicleType: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    }),
  ),
  loading: PropTypes.bool,
}

export default withTranslation()(VehicleCount)
